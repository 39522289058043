import React from 'react'

const DownloadView = () => (
	<div
		style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100vw',
			height: '100vh',
		}}
	>
		<img
			src="/appicon.png"
			style={{ width: '300', height: 300, borderRadius: 20 }}
		/>
		<a
			href="https://play.google.com/store/apps/details?id=com.krokodiili.konnichiwa"
			target="__blank"
		>
			<img src="/google.png" style={{ width: 400 }} />
		</a>
		<a
			href="https://apps.apple.com/fi/app/konnichiwa-sushi/id1540278611?l=fi"
			target="__blank"
		>
			<img src="/apple.svg" style={{ width: 350 }} />
		</a>
	</div>
)

export default DownloadView
