import React, { useContext } from 'react'
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	styled,
	Typography,
} from '@material-ui/core'
import { ICampaign } from './index'
import { Delete } from '@material-ui/icons'
import { useWebSocket } from '../../../App'
import * as datefns from 'date-fns'

const CampaignCard: React.FC<{ data: ICampaign }> = ({ data }) => {
	const { ws } = useWebSocket()

	const handleDelete = () => {
		if (ws) {
			ws.send(
				JSON.stringify({
					action: 'removeCampaign',
					campaignId: data.id,
				}),
			)
		}
	}

	return (
		<StyledCard>
			<Wrapper>
				<CardHeader
					title={data.title}
					style={{ flex: 1 }}
					subheader={`Valid until ${datefns.format(
						new Date(data.expiresAt),
						'dd.LL.yyyy HH:mm',
					)}`}
				/>
				<div>
					<IconButton onClick={handleDelete}>
						<Delete />
					</IconButton>
				</div>
			</Wrapper>
			<CardContent>
				<Typography>{data.description}</Typography>
			</CardContent>
		</StyledCard>
	)
}

export const StyledCard = styled(Card)({
	padding: 10,
	marginBottom: 10,
})

const Wrapper = styled(Box)({
	display: 'flex',
	alignItems: 'center',
})
export default CampaignCard
