import React from 'react'
import styled from 'styled-components'
import { Button, Text } from '.'

interface ModalProps {
	handleQRCodeRequest: Function
}

const ModalBase = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.9);
`

const Container = styled.div`
	display: flex;
	justify-content: center;
`

const Modal: React.FC<ModalProps> = ({ handleQRCodeRequest }) => {
	return (
		<ModalBase>
			<Text>A customer is attempting to use a QR code voucher</Text>
			<Container>
				<Button onClick={() => handleQRCodeRequest('accept')}>
					Accept
				</Button>
				<Button onClick={() => handleQRCodeRequest('reject')}>
					Reject
				</Button>
			</Container>
		</ModalBase>
	)
}

export default Modal
