import React from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button, TextInput } from '../../components'
import styled from 'styled-components'
import { API_URL } from '../../consts'
import { useMessages } from '../../components/MessageProvider'
import Axios from 'axios'

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-content: center;
`

const AcceptFreeBuffet: React.FC = () => {
	const { alert, success } = useMessages()
	const handleSubmit = (values: { code: string }, actions: any) => {
		Axios({
			url: `${API_URL}/use-code`,
			method: 'POST',
			data: values,
		})
			.then((response) => {
				console.log('Accept Free Buffet response', response)
				success('Code was accepted')
			})
			.catch((error) => {
				alert(error.response.data || error.message)
			})

		actions.resetForm()
	}
	return (
		<Formik
			initialValues={{ code: '' }}
			validationSchema={Yup.object().shape({
				code: Yup.string().required(),
			})}
			onSubmit={(values, actions) => handleSubmit(values, actions)}
		>
			<Form>
				<Container>
					<Field
						name="code"
						label="Accept Free Buffet Coupon"
						autoComplete="off"
						as={TextInput}
					/>
					<Button type="submit">Validate</Button>
				</Container>
			</Form>
		</Formik>
	)
}

export default AcceptFreeBuffet
