import { isBefore, parseISO, isToday } from 'date-fns'
import React, { useContext } from 'react'
import { Title, Text } from '../../components'
import { GlobalStateContext, useWebSocket } from '../../App'
import Reservation from './ReservationCard'

interface IReservation {
	id: string
}

const Reservations: React.FC<{
	reservations: IReservation[]
	setReservations: (updated: IReservation[]) => any
}> = ({ reservations, setReservations }) => {
	const { ws } = useWebSocket()

	const sortReservationsByTime = (resArr: any) =>
		resArr.sort((a: any, b: any) =>
			isBefore(parseISO(a.time), parseISO(b.time)) ? -1 : 1,
		)

	const { today, later } = reservations.reduce(
		(acc: any, curr: any) =>
			isToday(parseISO(curr.time))
				? { today: [...acc.today, curr], later: acc.later }
				: { today: acc.today, later: [...acc.later, curr] },
		{
			today: [],
			later: [],
		},
	)

	const handleCancelReservation = (id: number) => {
		if (ws) {
			ws.send(
				JSON.stringify({
					id,
					action: 'cancelReservation',
				}),
			)
		}
	}

	const handleMarkAsSeen = (id: string) => {
		setReservations(
			reservations.map((reservation: any) => {
				if (reservation.id === id) {
					return {
						seen: true,
						...reservation,
					}
				} else {
					return reservation
				}
			}),
		)
	}

	return (
		<div>
			<Title>Today</Title>
			{today.length ? (
				sortReservationsByTime(today).map((reservation: any) => (
					<Reservation
						key={reservation.id}
						cancel={handleCancelReservation}
						markAsSeen={() => handleMarkAsSeen(reservation.id)}
						{...reservation}
					/>
				))
			) : (
				<Text> No reservations </Text>
			)}

			<hr style={{ width: '100%' }} />

			<Title>Later</Title>
			{later.length ? (
				sortReservationsByTime(later).map((reservation: any) => (
					<Reservation
						key={reservation.id}
						cancel={handleCancelReservation}
						markAsSeen={() => handleMarkAsSeen(reservation.id)}
						{...reservation}
					/>
				))
			) : (
				<Text> No reservations </Text>
			)}
		</div>
	)
}

export default Reservations
