import React from 'react'
import { Tab, Tabs } from '@material-ui/core'

const NavTabs: React.FC<{
  selected: 'reservations' | 'campaigns' | 'feedback'
  onChange: (value: 'reservations' | 'campaigns') => void
}> = ({ selected, onChange }) => {
  return (
    <Tabs value={selected} onChange={(_, value) => onChange(value)}>
      <Tab
        value="reservations"
        label="Reservations"
        style={{ color: 'white' }}
      />
      <Tab value="campaigns" label="Campaigns" style={{ color: 'white' }} />

      <Tab value="feedback" label="Feedback" style={{ color: 'white' }} />
    </Tabs>
  )
}

export default NavTabs
