import React, { useContext, useRef, useEffect, useState } from 'react'
import { BottomBar, Content, Text, TopBar } from '../../components'
import { Redirect } from 'react-router-dom'
import Modal from '../../components/Modal'
import { SocketContext, useGlobalState } from '../../App'
import AcceptFreeBuffet from './AcceptFreeBuffet'
import useSound from 'use-sound'
import { useMessages } from '../../components/MessageProvider'
import Tabs from './Tabs'
import Reservations from './Reservations'
import CampaignsView from './campaigns'
import FeedbackView from './feedback'

type TabValues = 'reservations' | 'campaigns' | 'feedback'

const MainView: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<TabValues>('reservations')
  const [reservations, setReservations] = useState<any>([])
  const [modalActive, setModalActive] = useState(false)
  const { state, setState } = useGlobalState()
  const { success } = useMessages()
  const { ws } = useContext<any>(SocketContext)
  const timeout = useRef<any>(null)

  // Sound effects
  const [bellRings] = useSound('beep.mp3', { volume: 1 })
  const [scanSound] = useSound('scan.mp3', { volume: 1 })
  useEffect(() => {
    if (ws && ws.send) {
      ws.send(JSON.stringify({ action: 'tableReservations' }))
      ws.send(JSON.stringify({ action: 'restaurantFeedback' }))
    }
  }, [ws])

  useEffect(() => {
    let interval: any
    if (ws && ws.send) {
      interval = setInterval(() => {
        ws.send(JSON.stringify({ action: 'PING!' }))
      }, 450000)
    }

    return function cleanup() {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [ws])

  if (!ws || !ws.send) {
    return <Redirect to="/" />
  }

  ws.onmessage = (evt: any) => {
    const data = JSON.parse(evt.data)

    switch (data.action) {
      case 'qr':
        if (!modalActive) {
          setModalActive(data.requestId)
          scanSound()
          timeout.current = setTimeout(() => {
            handleQRAuthorization('reject')
          }, 15000)
        }
        break
      case 'authorize':
        success('QR Code Accepted')
        break

      case 'restaurantFeedbackSuccess':
        console.log(data, 'JEE')
        setState({
          ...state,
          feedback: data.payload,
        })
        break
      case 'postCampaignSuccess':
        success('Campaign added successfully!')
        setState({
          ...state,
          campaigns: [...(state.campaigns || []), data.payload],
        })
        break
      case 'removeCampaignSuccess':
        success('Campaign removed successfully!')
        setState({
          ...state,
          campaigns:
            state.campaigns?.filter(
              (campaign) => campaign.id !== data.payload,
            ) || [],
        })
        break
      case 'cancelReservationSuccess':
        setReservations((reservations: any) =>
          reservations.filter((r: any) => r.id !== data.payload),
        )
        success('Reservation successfully deleted')
        break
      case 'tableReservationsSuccess':
        data.payload.forEach((_: any, index: number) => {
          data.payload[index].seen = true
        })
        setReservations([...reservations, ...data.payload])
        break

      case 'newTableReservation':
        setReservations([...reservations, ...[data.payload]])
        bellRings()
        break
      default:
        console.log(data)
    }
  }

  const handleQRAuthorization = (action: 'accept' | 'reject') => {
    if (action === 'accept') {
      const data = {
        action: 'authorize',
        requestId: modalActive,
      }
      ws.send(JSON.stringify(data))
    }

    clearTimeout(timeout.current)

    timeout.current = null

    setModalActive(false)
  }

  if (!reservations) {
    return (
      <Content>
        <Text>Just a second, please ...</Text>
      </Content>
    )
  }

  return (
    <>
      <TopBar>
        <Tabs selected={selectedTab} onChange={setSelectedTab} />
      </TopBar>

      <Content>
        {modalActive ? (
          <Modal handleQRCodeRequest={handleQRAuthorization} />
        ) : null}
        {selectedTab === 'reservations' && (
          <Reservations
            reservations={reservations}
            setReservations={setReservations}
          />
        )}
        {selectedTab === 'feedback' && <FeedbackView />}

        {selectedTab === 'campaigns' && <CampaignsView />}
      </Content>
      <BottomBar>
        <AcceptFreeBuffet />
      </BottomBar>
    </>
  )
}

export default MainView
