import { Box, Fab, styled, Typography } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import React, { useEffect, useState, useContext } from 'react'
import { GlobalStateContext, useWebSocket } from '../../../App'
import { getCampaigns } from '../../../effects'
import CampaignCard from './Campaign'
import CampaignForm from './CampaignForm'

export interface ICampaign {
	title: string
	description: string
	id?: string
	expiresAt: string
	restaurantId: string
}

const CampaignsView = () => {
	const [showCreate, setShowCreate] = useState<boolean>(false)
	const { state, setState } = useContext<any>(GlobalStateContext)
	const { ws } = useWebSocket()
	const { campaigns } = state

	useEffect(() => {
		if (!state.campaigns) {
			;(async () => {
				const result = await getCampaigns(state.restaurantId)
				setState({ ...state, campaigns: result })
			})()
		}
	}, [setState, state])

	const handleNewCampaign = (data: ICampaign) => {
		if (ws) {
			ws.send(
				JSON.stringify({
					action: 'postCampaign',
					...data,
				}),
			)
		}
	}

	if (!campaigns) {
		return <div>LOADING</div>
	}

	return (
		<div>
			<TitleWrapper>
				<Typography variant="h4"> Current campaigns </Typography>
				<Fab
					color="secondary"
					onClick={() => setShowCreate(!showCreate)}
				>
					{showCreate ? <Close /> : <Add />}
				</Fab>
			</TitleWrapper>
			{showCreate && (
				<CampaignForm
					restaurantId={state.restaurantId}
					onSubmit={handleNewCampaign}
				/>
			)}

			{campaigns.map((campaign: ICampaign) => (
				<CampaignCard key={campaign.id} data={campaign} />
			))}
		</div>
	)
}

const TitleWrapper = styled(Box)({
	display: 'flex',
	color: 'white',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: 20,
})

export default CampaignsView
