import axios from "axios"
import { API_URL } from "../consts"

export const getCampaigns = async (restaurantId: string) => {
try {
const res = await axios.get(`${API_URL}/campaigns?restaurantId=${restaurantId}`)

return res.data
}
catch(error) {
    console.log(error)
    throw error
}
}