import { AppBar, Toolbar } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { useMessages } from './MessageProvider'

//TODO: SNACKBAR FOR ERROR MESSAGES

export const Text = styled.p`
	display: flex;
	color: #fff;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	margin: 1rem;
`
export const Title = styled.h1`
	display: flex;
	color: #fff;
	font-family: Roboto, sans-serif;
`

export const Button = styled.button.attrs(
	(props: { type: 'submit' | null }) => ({
		type: props.type || 'button',
	}),
)`
	background-color: rgba(0, 0, 0, 0);
	border: 3px solid #f1f1f1;
	border-radius: 4px;
	color: #f1f1f1;
	font: bold 1.5rem Roboto, sans-serif;
	padding: 0.5rem;
	transition: background-color 0.3s, color 0.3s;
	margin: 0.5rem;
	&:hover {
		color: rgba(0, 0, 0);
		background-color: #fff;
	}
`

const Overlay = styled.div`
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	background-size: cover;
	overflow-y: auto;
`
const BackgroundImage = styled.div`
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #555;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
`
export const Wrapper = (props: { children: any }) => {
	return (
		<BackgroundImage>
			<Overlay>{props.children}</Overlay>
		</BackgroundImage>
	)
}

export const TopBar = styled(({ className, children }) => (
	<AppBar style={{ backgroundColor: '#000' }}>
		<Toolbar>
			<img src="konnichiwa.jpg" alt="Konnichiwa!" height="100" />

			<div style={{ flex: 1 }} />
			{children}
		</Toolbar>
	</AppBar>
))`
	display: flex;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	padding: 0.5rem;
	width: 100%;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
	background-color: #000;
	z-index: 1;
	padding: 0.5rem;
`

export const BottomBar = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	z-index: 1;
	padding: 0.5rem;
`

export const Content = styled.div`
	position: unset;
	display: flex;
	flex-direction: column;
	max-width: 90%;
	margin: 7.5rem auto 7.5rem auto;
`
const TextInputdBase = styled.input.attrs((props: { type?: 'password' }) => ({
	type: props.type || 'text',
}))`
	color: #fff;
	font: bold 24px Roboto, sans-serif;
	background-color: rgba(0, 0, 0, 0);
	padding: 0.5rem;
	border: 3px solid #fff;
	padding-left: 1rem;
	border-radius: 4px;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	max-width: 400px;
	&::placeholder {
		display: none;
	}
`

export const TextInput: React.FC<{
	label: string
	type?: 'password'
}> = (props) => {
	const { label, type, ...otherProps } = props
	return (
		<TextInputdBase type={type} style={{ width: '100%' }} {...otherProps} />
	)
}

const Snackbar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 50%;
	right: auto;
	transform: translateX(-50%);
	& * {
		font-weight: bold;
		font-size: 1.25rem;
		border: none;
	}
	& *:hover {
		background-color: rgba(0, 0, 0, 0);
		color: #fff;
	}
	border-radius: 6px;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset,
		-4px -4px 4px rgba(0, 0, 0, 0.25) inset;
	${(props: { key: string; order: number; type: 'error' | 'success' }) => {
		return `bottom: ${props.order * 4 + 7.5}rem;
			background-color: ${
				props.type === 'error'
					? '#dd2255'
					: 'success'
					? '#119944'
					: '#555555'
			};`
	}}
`

export const SnackbarList = (props: { messages: any }) => {
	const { deleteMessage } = useMessages()
	return props.messages.map(
		(
			item: { id: string; type: 'error' | 'success'; text: string },
			index: number,
		) => (
			<Snackbar key={item.id} order={index} type={item.type}>
				<Text>{item.text}</Text>
				<Button onClick={() => deleteMessage(item.id)}>X</Button>
			</Snackbar>
		),
	)
}
