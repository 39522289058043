import React from 'react'
import styled from 'styled-components'
import { Content, Text, Title, TopBar } from '../components'

export default () => {
  return (
    <RootWrapper>
      <TopBar />

      <Content>
        <h2>YKSITYISYYSKÄYTÄNTÖ</h2>
        <h2> Keruu ja käyttötarkoitus </h2>
        Seuraavat yksityisyyskäytännöt koskevat Konnichiwa Sushi applikaatiota.
        Applikaatio ei kerää mitään henkilötietojasi (esim. nimeäsi,
        osoitettasi, puhelinnumeroasi tai sähköpostiosoitettasi) tietämättäsi
        tai luvattasi, ellet ole antanut meille lupaa.
        <p>
          Tällä hetkellä applikaatiossa kerätään vain tarvittavat tiedot, jotta
          kerätyt leimat, pöytävaraukset ja koodit saadaan yhdistettyä
          asiakkaaseen, jolle ne kuuluvat.
        </p>
        <p>
          Kameraa käytetään ainoastaan QR-koodien lukemiseen, eikä kuvauksesta
          kerätä luetun linkin lisäksi muuta tietoa.
        </p>
        <hr style={{ width: '100%' }} />
        <p>
          Lupaamme huolehtia yksityisyyden suojan periaatteiden ja
          henkilötietolainsäädännön noudattamisesta. Tietoja käsitellään
          huolellisesti sekä luottamuksellisesti, eikä niitä luovuteta
          kolmansille osapuolille. Jos sinulla on kysyttävää miten tietojasi
          käsitellään, ota yhteyttä:
        </p>
        <a href="mailto:info@konnichiwa.fi">info@konnichiwa.fi</a>
        <hr style={{ width: '100%' }} />
        <h2> Muutokset yksityisyyskäytäntöön </h2>
        <p>
          Yksityisyyskäytäntöjemme mahdollisista muutoksista ilmoitetaan tällä
          sivulla. Käyttämällä applikaatiota annat meille luvan soveltaa sen
          hetkistä yksityisyyskäytäntöämme. Emme koskaan käytä tietojasi
          yksityisyyskäytännöstä poikkeavalla tavalla ilman lupaasi.
        </p>
      </Content>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  background-color: #fff;
  min-height: 80vh;
  max-width: 90vw;
  margin: 0 auto;
`
