import React, { useContext } from 'react'
import { Button, Content, TextInput, TopBar } from '../../components'
import { useHistory } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { GlobalStateContext, SocketContext } from '../../App'
import { WS_URL } from '../../consts'
import { useMessages } from '../../components/MessageProvider'

const LoginView: React.FC = () => {
  const history = useHistory()
  const stateContext = useContext<any>(GlobalStateContext)
  const wsContext = useContext<any>(SocketContext)
  const { alert } = useMessages()

  const handleLogin = (values: { username: string; password: string }) => {
    const { username, password } = values
    const ws = new WebSocket(
      `${WS_URL}?user=${username}&password=${password}`,
    ) as any

    ws.onerror = (evt: any) => {
      alert('Connection failed')
    }

    ws.onclose = (evt: any) => {
      console.log('CLOSED', evt)
      wsContext.set(null)
    }

    ws.onopen = (evt: any) => {
      stateContext.setState({
        restaurantId: username,
      })
      wsContext.set(ws)
      setTimeout(() => {
        history.push('/main')
      }, 200)
    }
  }

  return (
    <>
      <TopBar />

      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={handleLogin}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('Required field'),
          password: Yup.string().required('Required field'),
        })}
      >
        <Form>
          <Content
            style={{
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Field name="username" placeholder="Username" as={TextInput} />
            <Field
              name="password"
              placeholder="Password"
              type="password"
              as={TextInput}
            />
            <Button type="submit">Login</Button>
          </Content>
        </Form>
      </Formik>
    </>
  )
}

export default LoginView
