import { Star } from '@material-ui/icons'
import React, { useContext } from 'react'
import { GlobalStateContext, IFeedback } from '../../../App'
import Rating from '@material-ui/lab/Rating'
import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'

const FeedbackView = () => {
  const { state } = useContext<any>(GlobalStateContext)

  if (!state.feedback) {
    return <CircularProgress />
  }

  const foodAverage =
    state.feedback?.reduce(
      (acc: number, curr: IFeedback) => acc + curr.food,
      0,
    ) / state.feedback.length

  const serviceAverage =
    state.feedback?.reduce(
      (acc: number, curr: IFeedback) => acc + curr.service,
      0,
    ) / state.feedback.length

  const commentsSortedByDate = state.feedback
    ?.filter((fb: IFeedback) => !!fb.comment)
    .sort(function (a: IFeedback, b: IFeedback) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (!b.createdAt) {
        return -1
      }
      if (!a.createdAt) {
        return 1
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

  return (
    <div>
      <Typography variant="h4" style={{ marginBottom: 20, color: 'white' }}>
        Feedback given from the mobile app
      </Typography>
      <div style={{ display: 'flex' }}>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography color="secondary" component="legend">
            Food
          </Typography>
          <Rating
            title="Food"
            name="hover-feedback"
            value={foodAverage}
            precision={0.25}
          />
        </Box>

        <Box component="fieldset" mb={3} borderColor="transparent">
          <Typography component="legend" color="secondary">
            Service
          </Typography>
          <Rating
            title="Food"
            name="hover-feedback"
            value={serviceAverage}
            precision={0.25}
          />
        </Box>
      </div>
      <List>
        {commentsSortedByDate.map((fb: IFeedback) => (
          <ListItem style={{ backgroundColor: 'white', marginBottom: 10 }}>
            <ListItemText
              primary={fb.comment}
              secondary={
                fb.createdAt
                  ? new Date(fb.createdAt).toLocaleDateString()
                  : 'Date unavailable'
              }
            />
            <Divider variant="inset" component="li" />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default FeedbackView
