import React from 'react'
import styled from 'styled-components'
import { Text, Button } from '../../components'
import { BsPersonFill } from 'react-icons/bs'
import { format } from 'date-fns'

interface ReservationProps {
  id: string
  restaurantId: string
  reservationNumber: number
  peopleCount: number
  time: string
  createdAt: string
  reservedBy: string
  email: string
  phone: string
  seen: boolean
  cancel: Function
  markAsSeen: Function
}

const ReservationCard = styled.div`
  display: grid;

  grid-template-columns: 0.1fr 2fr 1fr 1fr 2fr 2fr 1fr;
  justify-content: start;
  align-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #f1f1f1;
  font: bold 24px Roboto, sans-serif;
  padding: 1rem;
  margin: 0.5rem 0;
`

const NewReservationIcon = styled.div`
  display: flex;
  background-color: #fff;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: auto;
  ${(props: { seen: boolean }) => (props.seen ? 'visibility: hidden;' : null)}
`

const Reservation: React.FC<ReservationProps> = (props) => {
  const {
    id,
    peopleCount,
    time,
    reservedBy,
    email,
    phone,
    seen,
    cancel,
    markAsSeen,
  } = props

  return (
    <ReservationCard onClick={() => markAsSeen()}>
      <NewReservationIcon seen={seen || false} />
      <Text>{time && format(new Date(time), 'dd.MM.yyyy HH:mm')}</Text>
      <Text>
        {peopleCount} <BsPersonFill style={{ fontSize: 40 }} />
      </Text>
      <Text>{reservedBy}</Text>
      <Text>{email}</Text>
      <Text>{phone}</Text>
      <Button onClick={() => cancel(id)}>Cancel</Button>
    </ReservationCard>
  )
}

export default Reservation
