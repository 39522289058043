import {
	Button,
	CardActions,
	CardContent,
	styled,
	Box,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { ICampaign } from '.'
import { TextField, TextFieldProps } from 'formik-material-ui'
import { StyledCard } from './Campaign'
import { DateTimePicker } from 'formik-material-ui-pickers'
import { Save } from '@material-ui/icons'

const TextArea: React.FC<TextFieldProps> = (props) => (
	<TextField
		InputProps={{ rows: 3, multiline: true, required: true }}
		{...props}
	/>
)

const CampaignForm: React.FC<{
	restaurantId: string
	onSubmit: (data: ICampaign) => void
}> = ({ restaurantId, onSubmit }) => {
	return (
		<StyledCard>
			<Formik
				initialValues={{
					restaurantId,
					expiresAt: new Date(),
					title: '',
					description: '',
				}}
				onSubmit={(values, bag) => {
					onSubmit({
						...values,
						expiresAt: values.expiresAt.toISOString(),
					})

					bag.resetForm()
				}}
			>
				<Form>
					<CardContent>
						<ContentWrapper>
							<Field
								name="title"
								label="Title"
								component={TextField}
								required
							/>

							<Spacer />
							<Field
								name="description"
								label="Description"
								component={TextArea}
								required
							/>
							<Spacer />
							<Field
								name="expiresAt"
								label="Expiration date"
								component={DateTimePicker}
								required
							/>
						</ContentWrapper>
					</CardContent>

					<CardActions>
						<Button type="submit">
							<Save style={{ marginRight: 10 }} />
							SAVE
						</Button>
					</CardActions>
				</Form>
			</Formik>
		</StyledCard>
	)
}

const Spacer = styled(Box)({
	margin: 10,
})

const ContentWrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
})

export default CampaignForm
