import React, { useContext } from 'react'
import { SnackbarList } from '.'
import uniqid from 'uniqid'

export interface MessageContextProps {
	messages: any[]
	alert: (text: string) => void
	success: (text: string) => void
	deleteMessage: (messageId: string) => void
}

interface MessageProps {
	id: string
	type: 'success' | 'error'
	text: string
}

const MessageContext = React.createContext<MessageContextProps>({
	messages: [],
	alert: (text: string) => console.log(text),
	success: (text: string) => console.log(text),
	deleteMessage: (messageId: string) => console.log(messageId),
})

export const useMessages = () => {
	return useContext(MessageContext)
}

const MessageProvider = (props: { children: any }) => {
	const [messages, setMessages] = React.useState<any[]>([])
	const timeouts = React.useRef<any[]>([])

	const addMessage = (type: 'error' | 'success', text: string) => {
		let messageId = uniqid()

		setMessages([{ id: messageId, type: type, text: text }, ...messages])

		timeouts.current = [
			...timeouts.current,
			{
				id: messageId,
				timeout: setTimeout(() => {
					handleDeleteMessage(messageId)
				}, 5000),
			},
		]
	}

	const handleDeleteMessage = (messageId: string) => {
		setMessages((messages: MessageProps[]) =>
			messages.filter((item: MessageProps) => {
				if (item.id === messageId) {
					return false
				}
				return true
			}),
		)

		timeouts.current.forEach(
			(t) => t.id === messageId && clearTimeout(t.timeout),
		)
	}

	const alert = (text: string) => addMessage('error', text)
	const success = (text: string) => addMessage('success', text)

	return (
		<MessageContext.Provider
			value={{
				messages,
				alert: alert,
				success: success,
				deleteMessage: handleDeleteMessage,
			}}
		>
			{props.children}
			{messages.length > 0 && <SnackbarList messages={messages} />}
		</MessageContext.Provider>
	)
}

export default MessageProvider
