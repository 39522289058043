import React, { createContext, useContext, useMemo, useState } from 'react'
import { Wrapper } from './components'
import MainView from './views/main'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LoginView from './views/login'
import PolicyView from './views/PolicyView'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ICampaign } from './views/main/campaigns'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import DownloadView from './download'

export interface StateData {
  restaurantId: string | null
  campaigns: ICampaign[] | null
  feedback: IFeedback[] | null
}

export interface IFeedback {
  food: number
  service: number
  comment: string
  restaurantId: string
  createdAt?: string
}

export interface GlobalState {
  state: StateData
  setState: (updatedState: StateData) => void
}

export const GlobalStateContext = createContext<GlobalState>({
  state: {
    restaurantId: '',
    campaigns: [],
    feedback: [],
  },
  setState: () => {},
})

type SocketState = {
  ws: WebSocket | null
  set: (conn: WebSocket) => void
}

export const SocketContext = createContext<SocketState>({
  ws: null,
  set: () => {},
})

export const useGlobalState = () => useContext<GlobalState>(GlobalStateContext)
export const useWebSocket = () => useContext<SocketState>(SocketContext)

function App() {
  const [val, setVal] = useState<StateData>({
    restaurantId: null,
    campaigns: null,
    feedback: null,
  })

  const [ws, set] = useState<WebSocket | null>(null)

  const wsState = useMemo(
    () => ({
      ws,
      set,
    }),
    [ws],
  )

  return (
    <MuiThemeProvider
      theme={createMuiTheme({
        palette: {
          primary: {
            main: '#00aced',
            light: '#65deff',
            dark: '#007dba',
          },
          secondary: {
            main: '#77A259',
            light: '#3e5f93',
            dark: '#00103a',
          },
        },
      })}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Switch>
            <SocketContext.Provider value={wsState}>
              <GlobalStateContext.Provider
                value={{
                  state: val,
                  setState: setVal,
                }}
              >
                <Wrapper>
                  <Route exact path="/" component={LoginView} />
                  <Route exact path="/main" component={MainView} />
                  <Route exact path="/policy" component={PolicyView} />
                  <Route exact path="/download" component={DownloadView} />
                </Wrapper>
              </GlobalStateContext.Provider>
            </SocketContext.Provider>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  )
}

export default App
